.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.farbe input:checked{
  background-color: #4ed164;
  border-color: #4ed164;
}

.hinter{
  background-color: #8a8a8a;

}

.bigger{
  font-size: 57px;
}

a{
  cursor: pointer;
}

td{
  cursor: pointer;
}

.stil{
  font-size: 25px;
}

.umrandung{
  cursor: pointer;
}

.bg-successs {
  background-color: #28a745!important;
}

.yellow {
  background-color: yellow;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.grey {
  background-color: rgb(136, 134, 134);
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h5{
  cursor: pointer;
}
